import React, { FC, useState } from 'react';
import './index.less';
import { Form, Input, Select, Button, message } from 'antd';
import { axiosPost } from '@/utils/axios';

interface IPropTypes {
  className?: string;
}

const Booknow: FC<IPropTypes> = function ({ className }) {
  const [form] = Form.useForm(); // Create a form instance
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSendContact = async (values: any) => {
    const { email, fullName, storeUrl, mobile, supportAgents, ecommercePlatform } = values;

    try {
      await axiosPost(`${process.env.REACT_APP_API_ROOT}contact`, {
        email,
        fullName,
        storeUrl,
        mobile: parseInt(mobile, 10),
        supportAgents: parseInt(supportAgents, 10),
        ecommercePlatform,
      });
      setIsSubmitted(true);
      message.success('Request sent successfully');
    } catch (error) {
      message.error('Failed to send request');
    }
  };

  return (
    <div className={`cor-mobileBooknow`}>
      <div className="cor-mobileBooknow-left">
        <div className="cor-mobileBooknow-left-1"></div>
        <div className="cor-mobileBooknow-left-2">30 Minutes Today Might Mean 30% Growth Tomorrow</div>
        <div className="cor-mobileBooknow-left-3">
          Schedule a demo to see how Cornerstone AI drives measurable growth and improves your bottom line by unlocking
          your customers&apos; full potential.
        </div>
      </div>
      <div className="cor-mobileBooknow-right">
        <div className="cor-mobileBooknow-right-title">Book your personalized demo now</div>

        <Form
          form={form}
          onFinish={handleSendContact}
          requiredMark={false}
          disabled={isSubmitted}
          layout="vertical"
          className="cor-mobileBooknow-form"
          initialValues={{
            ecommercePlatform: 'Shopify',
          }}>
          <Form.Item
            name="email"
            label="Work email*"
            rules={[
              { required: true, message: 'Please enter your work email' },
              { type: 'email', message: 'Please enter a valid email address' },
            ]}>
            <Input placeholder="Enter your work email" />
          </Form.Item>

          <Form.Item
            name="fullName"
            label="Full name*"
            rules={[{ required: true, message: 'Please enter your full name' }]}>
            <Input placeholder="Enter your full name" />
          </Form.Item>

          <Form.Item
            name="storeUrl"
            label="Store URL*"
            rules={[
              { required: true, message: 'Please enter your store URL' },
              { type: 'url', message: 'Please enter a valid URL' },
            ]}>
            <Input placeholder="Enter your store URL" />
          </Form.Item>

          <Form.Item
            name="mobile"
            label="Mobile phone number*"
            rules={[
              { required: true, message: 'Please enter your mobile phone number' },
              {
                pattern: /^[0-9]{10,}$/,
                message: 'Please enter a valid phone number with at least 10 digits',
              },
            ]}>
            <Input placeholder="Enter your mobile phone number" />
          </Form.Item>

          <Form.Item
            name="supportAgents"
            label="Number of support agents*"
            rules={[
              { required: true, message: 'Please enter number of support agents' },
              {
                pattern: /^[0-9]+$/,
                message: 'Please enter a valid number',
              },
            ]}>
            <Input placeholder="Enter number of support agents" />
          </Form.Item>

          <Form.Item
            name="ecommercePlatform"
            label="Ecommerce platform"
            rules={[{ required: true, message: 'Please enter your ecommerce platform' }]}>
            <Select
              size="large"
              options={[
                { value: 'Shopify', label: 'Shopify' },
                { value: 'Magento2', label: 'Magento 2' },
                { value: 'Salesforce', label: 'Salesforce commerce cloud' },
                { value: 'Bigcommerce', label: 'Bigcommerce' },
                { value: 'WooCommerce', label: 'WooCommerce' },
                { value: 'Other', label: 'Other' },
              ]}
            />
          </Form.Item>

          <Form.Item>
            <Button className="cor-mobileBooknow-right-btn" type="primary" htmlType="submit">
              Request a Demo
            </Button>
          </Form.Item>
        </Form>

        <div className="tips1"></div>
        <div className="tips2">
          <div></div>
        </div>
        <div className="tips3">
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Booknow;
