/**
 * @description Component-Banner
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import Frame77 from './assets/Frame77.png';
interface IPropTypes {
  className?: string;
}
const Banner: FC<IPropTypes> = function () {
  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };
  return (
    <div className={`cor-mobilebanner`}>
      <div className="cor-mobilebanner-title">
        <div>The Leading Customer-Centric AI CX Platform</div>
      </div>
      <div className="cor-mobilebanner-subtitle">Interpret Multi-Channel Insights for Accelerated Growth</div>
      <div className="cor-mobilebanner-btn" onClick={handleScrollToBottom}>See it live</div>
      <img className="cor-mobilebanner-img" src={Frame77} />
      <div className="cor-mobilebanner-bottom">
        The first <span className='tips'>customer-centric</span> intelligent CX platform delivers personalized, real-time interactions that boosts
        conversions, ROI, and customer satisfaction.  
      </div>
    </div>
  );
};

export default Banner;
