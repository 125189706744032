/**
 * @description Component-Features
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import { ReactComponent as Icon_feature_01 } from './assets/icon_feature_01.svg';
import { ReactComponent as Icon_feature_02 } from './assets/icon_feature_02.svg';
import unnamed_1 from './assets/unnamed_1.png';
import unnamed_2 from './assets/unnamed_2.png';
import unnamed_3 from './assets/unnamed_3.png';
interface IPropTypes {
  className?: string;
}
const Features: FC<IPropTypes> = function () {
  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div className={`cor-mobilefeatures`}>
      <div className="cor-mobilefeatures-cantainer cor-mobilefeatures-type1 wow">
        <div className="cor-mobilefeatures-cantainer-left">
          <div className="cor-mobilefeatures-cantainer-left-1">Integrate data seamlessly to maximize ROI</div>
          <div className="cor-mobilefeatures-cantainer-left-2">
            Integrate all your brand data—emails, social comments, and reviews—in one place effortlessly. Automate
            routine tasks with our smart AI to save time and cut costs to give a significant boosting to your ROI.
          </div>
          <div className="cor-mobilefeatures-cantainer-left-3" onClick={handleScrollToBottom}>
            Request a Demo
          </div>
        </div>
        <img className="cor-mobilefeatures-cantainer-right" src={unnamed_1} />
        <div className="cor-mobilefeatures-cantainer-head cor-mobilefeatures-cantainer-head1">
          <div className="cor-mobilefeatures-cantainer-head-tips1">
            30% <Icon_feature_01 />
          </div>
          <div className="cor-mobilefeatures-cantainer-head-tips2">in ROI</div>
          <div className="cor-mobilefeatures-cantainer-head-left"></div>
          <div className="cor-mobilefeatures-cantainer-head-right">
            <div></div>
          </div>
        </div>
      </div>
      <div className="cor-mobilefeatures-cantainer cor-mobilefeatures-type2 wow">
        <div className="cor-mobilefeatures-cantainer-left">
          <div className="cor-mobilefeatures-cantainer-left-1">
            In-depth brand analysis power personalized interactions{' '}
          </div>
          <div className="cor-mobilefeatures-cantainer-left-2">
            Turn casual visitors into loyal customers with our in-depth brand analysis that provides valuable insights
            into customer behavior, enabling you to tailor interactions and craft experiences that resonate.
          </div>
          <div className="cor-mobilefeatures-cantainer-left-3" onClick={handleScrollToBottom}>
            Request a Demo
          </div>
        </div>
        <img className="cor-mobilefeatures-cantainer-right" src={unnamed_2} />
        <div className="cor-mobilefeatures-cantainer-head cor-mobilefeatures-cantainer-head2">
          <div className="cor-mobilefeatures-cantainer-head-tips1">
            30% <Icon_feature_01 />
          </div>
          <div className="cor-mobilefeatures-cantainer-head-tips2">in Conversion</div>
          <div className="cor-mobilefeatures-cantainer-head-left"></div>
          <div className="cor-mobilefeatures-cantainer-head-right">
            <div></div>
          </div>
        </div>
      </div>
      <div className="cor-mobilefeatures-cantainer cor-mobilefeatures-type3 wow">
        <div className="cor-mobilefeatures-cantainer-left">
          <div className="cor-mobilefeatures-cantainer-left-1">Deliver adaptive, real-time customer satisfaction</div>
          <div className="cor-mobilefeatures-cantainer-left-2">
            Deliver exceptional customer service with our AI-powered platform that puts customers first. Automate
            accurate, timely responses and continuously improve with adaptive learning, ensuring your customers feel
            valued and taken care of.
          </div>
          <div className="cor-mobilefeatures-cantainer-left-3" onClick={handleScrollToBottom}>
            Request a Demo
          </div>
        </div>
        <img className="cor-mobilefeatures-cantainer-right" src={unnamed_3} />
        <div className="cor-mobilefeatures-cantainer-head cor-mobilefeatures-cantainer-head3">
          <div className="cor-mobilefeatures-cantainer-head-tips1">98% in</div>
          <div className="cor-mobilefeatures-cantainer-head-tips2">Satisfaction</div>
          <div className="cor-mobilefeatures-cantainer-head-left"></div>
          <div className="cor-mobilefeatures-cantainer-head-right">
            <div></div>
          </div>
        </div>
      </div>
      <div className="cor-mobilefeatures-bottom ">
        <div className="cor-mobilefeatures-bottom-1">Our Solution Have Saved Our Clients Millions Of Dollars</div>
        <div className="cor-mobilefeatures-bottom-2">
          <div>60%</div>
          <Icon_feature_02 />
          <div>Operational Costs</div>
        </div>
        <div className="cor-mobilefeatures-bottom-3">
          Streamline your marketing operations and free up precious resources while boosting customer engagement.
        </div>
        <div className="cor-mobilefeatures-bottom-dian1"></div>
        <div className="cor-mobilefeatures-bottom-dian2"></div>
        <div className="cor-mobilefeatures-bottom-dian3"></div>
        <div className="cor-mobilefeatures-bottom-dian4"></div>
      </div>
    </div>
  );
};

export default Features;
